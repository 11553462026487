import React from 'react'

import Currency from './Currency'

import { pluralize } from '../utils/helper'

const SummaryTable = ({ result, onClick }) => {
  const {
    finalValue,
    totalInterest,
    principal,
    hasDepositsAndWithdrawals,
    totalDeposits,
    totalWithdrawals,
    yearlyInterestRate,
    tenureYears,
    tenureMonths,
    increaseDeposit,
    increaseWithdrawal,
    taxRate,
    tax,
  } = result

  const interestRate = Number((yearlyInterestRate * 100).toFixed(2))
  const hasDeposits = ['deposit', 'both'].includes(hasDepositsAndWithdrawals)
  const hasWithdrawals = ['withdrawal', 'both'].includes(
    hasDepositsAndWithdrawals
  )

  return (
    <div className="card summary-table" onClick={onClick}>
      <div className="result-group">
        <div className="result green">
          <div>Final amount</div>
          <Currency value={finalValue} />
        </div>
        <div className="result blue">
          <div>Principal amount</div>
          <Currency value={principal} />
        </div>
        {hasDeposits ? (
          <div className="result green">
            <div>
              Additional deposits
              {increaseDeposit ? (
                <small>
                  (<i className="bx bx-up-arrow-alt"></i>
                  {increaseDeposit}% p.a)
                </small>
              ) : null}
            </div>
            <Currency value={totalDeposits} />
          </div>
        ) : null}
        {hasWithdrawals ? (
          <div className="result red">
            <div>
              Total withdrawals
              {increaseWithdrawal ? (
                <small>
                  (<i className="bx bx-up-arrow-alt"></i>
                  {increaseWithdrawal}% p.a)
                </small>
              ) : null}
            </div>
            <Currency value={totalWithdrawals} />
          </div>
        ) : null}
      </div>

      <div className="result-group">
        <div className="result orange">
          <div>Total interest</div>
          <Currency value={totalInterest} />
        </div>
        <div className="result input-color">
          <div>Interest rate</div>
          <span>
            {interestRate}
            <small>%</small>
          </span>
        </div>
        <div className="result input-color">
          <div>Tenure</div>
          <span>
            {tenureYears ? (
              <>
                {tenureYears} <small>{pluralize(tenureYears, 'year')}</small>
              </>
            ) : null}{' '}
            {tenureMonths ? (
              <>
                {tenureMonths} <small>{pluralize(tenureMonths, 'month')}</small>
              </>
            ) : null}
          </span>
        </div>
        {taxRate ? (
          <div className="result red">
            <div>
              Tax
              <small>({taxRate}%)</small>
            </div>
            <Currency value={tax} />
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default SummaryTable
