import React from 'react'

import useLocalStorage from '../hooks/useLocalStorage'
import { LOCALE, USER_SETTINGS } from '../utils/constants'

const Currency = ({
  value,
  minFractionDigits,
  maxFractionDigits,
  defaultValue = '-',
}) => {
  const [showDecimalsInCurrency] = useLocalStorage(
    USER_SETTINGS.SHOW_DECIMALS_IN_CURRENCY,
    false
  )
  const [showRelativeValue] = useLocalStorage(
    USER_SETTINGS.SHOW_RELATIVE_VALUE,
    true
  )
  const fractionDigits = showDecimalsInCurrency ? 2 : 0
  const options = {
    minimumFractionDigits: minFractionDigits || fractionDigits,
    maximumFractionDigits: maxFractionDigits || fractionDigits,
  }

  let formattedValue
  let relativeValue = ''
  if (!value) {
    formattedValue = defaultValue
  } else if (showRelativeValue) {
    const relativeOptions = {
      minimumFractionDigits: showDecimalsInCurrency ? 2 : 0,
      maximumFractionDigits: 2,
    }
    if (Math.abs(value) >= 10000000) {
      formattedValue = parseFloat(value / 10000000).toLocaleString(
        LOCALE,
        relativeOptions
      )
      relativeValue = 'Cr'
    } else if (Math.abs(value) >= 100000) {
      formattedValue = parseFloat(value / 100000).toLocaleString(
        LOCALE,
        relativeOptions
      )
      relativeValue = 'L'
    } else if (Math.abs(value) >= 1000) {
      formattedValue = parseFloat(value / 1000).toLocaleString(
        LOCALE,
        relativeOptions
      )
      relativeValue = 'k'
    } else {
      formattedValue = value.toLocaleString(LOCALE, options)
    }
  } else {
    formattedValue = value.toLocaleString(LOCALE, options)
  }

  return formattedValue ? (
    <>
      {value ? (
        <span className="currency-symbol">
          {value < 0 ? <>-&nbsp;</> : null}
          <>₹</>
        </span>
      ) : null}
      <span>{formattedValue.replace(/-(.)/g, '$1')}</span>
      <span className="relative-value">{relativeValue}</span>
    </>
  ) : null
}

export default Currency
