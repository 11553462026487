import React, { useState } from 'react'

import BreakdownTable from './BreakdownTable'
import SummaryTable from './SummaryTable'

const Result = ({ result }) => {
  const [showBreakdown, setShowBreakdown] = useState(false)

  const handleClick = (e) => {
    e.persist()
    setShowBreakdown((prevState) => !prevState)
  }

  return (
    <>
      <SummaryTable result={result} onClick={handleClick} />
      {showBreakdown && <BreakdownTable result={result} />}
    </>
  )
}

export default Result
