export const LOCALE = 'en-IN'

export const CURRENCY_OPTIONS = Object.freeze({
  style: 'currency',
  currency: 'INR',
})

export const PERIOD_TO_MONTHS = {
  'monthly': 12,
  'quarterly': 4,
  'half-yearly': 2,
  'yearly': 1,
  'never': 0,
}

export const USER_SETTINGS = Object.freeze({
  SHOW_DECIMALS_IN_CURRENCY: 'SHOW_DECIMALS_IN_CURRENCY',
  SHOW_MONTHLY_BREAKDOWN: 'SHOW_MONTHLY_BREAKDOWN',
  ENABLE_DARK_THEME: 'ENABLE_DARK_THEME',
  SHOW_INCREASE_DEPOSIT_ANNUALLY_FIELD: 'SHOW_INCREASE_DEPOSIT_ANNUALLY_FIELD',
  SHOW_INCREASE_WITHDRAWAL_ANNUALLY_FIELD:
    'SHOW_INCREASE_WITHDRAWAL_ANNUALLY_FIELD',
  SHOW_RELATIVE_VALUE: 'SHOW_RELATIVE_VALUE',
  SHOW_INVESTMENT_GOAL_FIELD: 'SHOW_INVESTMENT_GOAL_FIELD',
  SHOW_TAX_RATE_FIELD: 'SHOW_TAX_RATE_FIELD',
})

export const INVESTMENT_FORM_DEFAULT_DATA = Object.freeze({
  interestRate: '',
  interestPeriod: 'yearly',
  tenureYears: '',
  tenureMonths: '',
  compoundingFrequency: 'yearly',
  hasDepositsAndWithdrawals: 'none',
  deposit: '',
  depositFrequency: 'monthly',
  increaseDeposit: '',
  withdrawal: '',
  withdrawalFrequency: 'monthly',
  increaseWithdrawal: '',
  taxRate: '',
})

export const ROUTES = Object.freeze({
  HOME: '/',
  SETTINGS: '/settings',
})
