import { CURRENCY_OPTIONS, LOCALE, PERIOD_TO_MONTHS } from './constants'

export const toCurrency = (value) => {
  return value.toLocaleString(LOCALE, CURRENCY_OPTIONS)
}

export const validateNumber = (value = '', validations = {}) => {
  const formattedValue = value.replaceAll(',', '')
  const { min, max, required } = validations

  if (required && formattedValue === '') return false
  if (formattedValue !== '' && isNaN(formattedValue)) return false

  const parsedValue = Number(formattedValue)

  if (min !== undefined && parsedValue < min) return false
  if (max !== undefined && parsedValue > max) return false

  return true
}

export const formatAsCommaSeparated = (e, formik) => {
  const { name, value } = e.target
  let formattedValue = value.replaceAll(',', '')
  if (formattedValue !== '' && isFinite(formattedValue)) {
    formattedValue = parseFloat(formattedValue).toLocaleString(LOCALE)
  }

  formik.setFieldValue(name, formattedValue)
}

export const pluralize = (count, noun, options = {}) => {
  const defaults = { suffix: 's', inclusive: false }
  const { suffix, inclusive } = { ...defaults, ...options }

  return `${inclusive ? count : ''}${noun}${count > 1 ? suffix : ''}`
}

/* 
  Compound interest:
  A = P(1+r/n)^(nt)

  Future value of a series: (Any additional contribution)
  FV = PMT × {[(1 + r/x)^(xt) - 1] / (r/x)}

  Where,
    A = future value of the investment/loan
    P = principal investment amount
    PMT = monthly payment amount
    r = annual interest rate (decimal)
    n = number of times interest is compounded per year
    x = number of times deposit is made per year
    t = time in years

  Ref: https://www.thecalculatorsite.com/finance/calculators/compound-interest-formula
 */
export const calculateCompoundInterest = ({
  principal,
  interestRate,
  interestPeriod,
  tenureYears,
  tenureMonths,
  compoundingFrequency,
  deposit,
  depositFrequency,
  withdrawal,
  increaseDeposit,
  withdrawalFrequency,
  hasDepositsAndWithdrawals,
  increaseWithdrawal,
  taxRate,
}) => {
  const tYears = parseInt(tenureYears || '0')
  const tMonths = parseInt(tenureMonths || '0')
  const increaseDepositPercentage = parseFloat(increaseDeposit || '0') * 0.01
  const increaseWithdrawalPercentage =
    parseFloat(increaseWithdrawal || '0') * 0.01

  const P = parseFloat(String(principal).replaceAll(',', '') || '0')
  const r =
    parseFloat(interestRate || '0') * 0.01 * PERIOD_TO_MONTHS[interestPeriod]
  const n = PERIOD_TO_MONTHS[compoundingFrequency]

  let d = parseFloat(deposit.replaceAll(',', '') || '0')
  const dn = PERIOD_TO_MONTHS[depositFrequency]

  let w = parseFloat(withdrawal.replaceAll(',', '') || '0')
  const wn = PERIOD_TO_MONTHS[withdrawalFrequency]

  const tenureInMonths = tYears ? tYears * 12 + tMonths : tMonths
  const monthlyInterestRate = r / 12

  const compoundingFrequencyInMonths = 12 / n
  const depositFrequencyInMonths = 12 / dn
  const withdrawalFrequencyInMonths = 12 / wn

  const hasDeposits = ['both', 'deposit'].includes(hasDepositsAndWithdrawals)
  const hasWithdrawals = ['both', 'withdrawal'].includes(
    hasDepositsAndWithdrawals
  )

  const taxPercentage = parseFloat(taxRate || '0') / 100

  const mMonths = [0]
  const mInterests = [0]
  const mAccruedInterests = [0]
  const mBalances = [P]
  const mDeposits = [P]
  const mWithdrawals = [0]
  const mTotalDepositsAndWithdrawals = [P]

  const yMonths = [0]
  const yInterests = [0]
  const yAccruedInterests = [0]
  const yBalances = [P]
  const yDeposits = [P]
  const yWithdrawals = [0]
  const yTotalDepositsAndWithdrawals = [P]

  let accruedInterestForCompounding = 0
  let year = 0
  let yearlyInterest = 0
  let yearlyDeposit = 0
  let yearlyWithdrawal = 0

  for (let i = 0; i < tenureInMonths; i++) {
    const currentMonth = i + 1
    let currentBalance = mBalances[i]
    let currentDeposit = 0
    let currentWithdrawal = 0
    let currentTotalDepositAndWithdrawal = mTotalDepositsAndWithdrawals[i]

    if (hasDeposits && currentMonth % depositFrequencyInMonths === 0) {
      currentDeposit = d
      currentBalance += d
      currentTotalDepositAndWithdrawal += d
    }

    const currentInterest =
      currentBalance > 0 ? currentBalance * monthlyInterestRate : 0
    accruedInterestForCompounding += currentInterest
    let currentAccruedInterest = mAccruedInterests[i] + currentInterest

    if (hasWithdrawals && currentMonth % withdrawalFrequencyInMonths === 0) {
      if (currentAccruedInterest >= w) {
        currentAccruedInterest -= w
        accruedInterestForCompounding -= w
      } else {
        currentBalance -= w - currentAccruedInterest
        currentTotalDepositAndWithdrawal -= w - currentAccruedInterest
        accruedInterestForCompounding = 0
        currentAccruedInterest = 0
      }

      currentWithdrawal = w
    }

    if (
      currentMonth % compoundingFrequencyInMonths === 0 ||
      currentMonth === tenureInMonths
    ) {
      currentBalance += accruedInterestForCompounding
      accruedInterestForCompounding = 0
    }

    // Calculate yearly interest, deposit and withdrawal
    yearlyInterest += currentInterest
    yearlyDeposit += currentDeposit
    yearlyWithdrawal += currentWithdrawal

    if (currentMonth % 12 === 0) {
      if (hasDeposits && increaseDepositPercentage) {
        d = d * (1 + increaseDepositPercentage)
      }

      if (hasWithdrawals && increaseWithdrawalPercentage) {
        w = w * (1 + increaseWithdrawalPercentage)
      }

      year++
      yMonths.push(year)
      yInterests.push(yearlyInterest)
      yAccruedInterests.push(currentAccruedInterest)
      yBalances.push(currentBalance)
      yDeposits.push(yearlyDeposit)
      yWithdrawals.push(yearlyWithdrawal)
      yTotalDepositsAndWithdrawals.push(currentTotalDepositAndWithdrawal)

      // Reset yearly values
      yearlyInterest = 0
      yearlyDeposit = 0
      yearlyWithdrawal = 0
    }

    mMonths.push(i + 1)
    mInterests.push(currentInterest)
    mAccruedInterests.push(currentAccruedInterest)
    mBalances.push(currentBalance)
    mDeposits.push(currentDeposit)
    mWithdrawals.push(currentWithdrawal)
    mTotalDepositsAndWithdrawals.push(currentTotalDepositAndWithdrawal)
  }

  const monthlyTableData = {
    months: mMonths,
    interests: mInterests,
    accruedInterests: mAccruedInterests,
    balances: mBalances,
    deposits: mDeposits,
    withdrawals: mWithdrawals,
    totalDepositsAndWithdrawals: mTotalDepositsAndWithdrawals,
  }

  const yearlyTableData = {
    months: yMonths,
    interests: yInterests,
    accruedInterests: yAccruedInterests,
    balances: yBalances,
    deposits: yDeposits,
    withdrawals: yWithdrawals,
    totalDepositsAndWithdrawals: yTotalDepositsAndWithdrawals,
  }

  const finalValueIndex = mMonths.length - 1
  const totalDeposits = mDeposits.reduce((pv, cv) => pv + cv, 0) - P
  const totalWithdrawals = mWithdrawals.reduce((pv, cv) => pv + cv, 0)
  const totalInterest = mAccruedInterests[finalValueIndex]
  const tax = totalInterest * taxPercentage

  return {
    monthlyTableData,
    yearlyTableData,
    principal: P,
    totalInterest: mAccruedInterests[finalValueIndex],
    finalValue: mBalances[finalValueIndex] - tax,
    totalDeposits,
    totalWithdrawals,
    hasDepositsAndWithdrawals: hasDepositsAndWithdrawals,
    yearlyInterestRate: r,
    tenureYears,
    tenureMonths,
    increaseDeposit: parseFloat(increaseDeposit || '0'),
    increaseWithdrawal: parseFloat(increaseWithdrawal || '0'),
    taxRate: parseFloat(taxRate || '0'),
    tax,
  }
}
