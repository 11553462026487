import { has } from 'lodash'
import React from 'react'
import { formatAsCommaSeparated } from '../utils/helper'

const Input = ({ formik, name, className = '', commaSeparated, ...props }) => {
  const errorClassName =
    has(formik.touched, name) && has(formik.errors, name) ? 'error' : ''
  const inputClassName = `${className}${errorClassName}`

  const inputChangeHandler = (e) => {
    if (!commaSeparated) return formik.handleChange(e)

    formatAsCommaSeparated(e, formik)
  }

  return (
    <input
      {...props}
      name={name}
      className={inputClassName}
      onChange={inputChangeHandler}
    />
  )
}

export default Input
