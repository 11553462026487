import React from 'react'

import Currency from './Currency'

import useLocalStorage from '../hooks/useLocalStorage'
import { USER_SETTINGS } from '../utils/constants'

const BreakdownTable = ({ result, monthly }) => {
  const [showMonthlyBreakdown] = useLocalStorage(
    USER_SETTINGS.SHOW_MONTHLY_BREAKDOWN,
    false
  )

  const hasDeposits = ['deposit', 'both'].includes(
    result.hasDepositsAndWithdrawals
  )
  const hasWithdrawals = ['withdrawal', 'both'].includes(
    result.hasDepositsAndWithdrawals
  )

  const {
    months,
    interests,
    accruedInterests,
    balances,
    deposits,
    withdrawals,
    totalDepositsAndWithdrawals,
  } = showMonthlyBreakdown ? result.monthlyTableData : result.yearlyTableData

  return (
    <div className="card breakdown-table">
      <table>
        <thead>
          <tr>
            <td>{showMonthlyBreakdown ? 'Month' : 'Year'}</td>
            {hasDeposits || hasWithdrawals ? (
              <td>Deposits & Withdrawals</td>
            ) : null}
            <td>Interest</td>
            {hasDeposits || hasWithdrawals ? (
              <td>Total Deposits & Withdrawals</td>
            ) : null}
            <td>Accrued Interest</td>
            <td>Balance</td>
          </tr>
        </thead>
        <tbody>
          {months.map((_m, index) => {
            return (
              <tr key={index}>
                <td>{months[index]}</td>
                {hasDeposits || hasWithdrawals ? (
                  <td>
                    {hasDeposits ? (
                      <div>
                        <Currency value={deposits[index]} />
                      </div>
                    ) : null}
                    {hasWithdrawals && index !== 0 ? (
                      <div className="error">
                        (<Currency value={withdrawals[index]} />)
                      </div>
                    ) : null}
                  </td>
                ) : null}
                <td>
                  <Currency value={interests[index]} />
                </td>
                {hasDeposits || hasWithdrawals ? (
                  <td>
                    <Currency value={totalDepositsAndWithdrawals[index]} />
                  </td>
                ) : null}
                <td>
                  <Currency value={accruedInterests[index]} />
                </td>
                <td>
                  <Currency value={balances[index]} />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default BreakdownTable
