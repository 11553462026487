import React from 'react'

import useLocalStorage from '../hooks/useLocalStorage'
import { USER_SETTINGS } from '../utils/constants'

const Settings = () => {
  const [showDecimalsInCurrency, setShowDecimalsInCurrency] = useLocalStorage(
    USER_SETTINGS.SHOW_DECIMALS_IN_CURRENCY,
    false
  )
  const [showRelativeValue, setShowRelativeValue] = useLocalStorage(
    USER_SETTINGS.SHOW_RELATIVE_VALUE,
    true
  )
  const [
    showIncreaseDepositAnnuallyField,
    setShowIncreaseDepositAnnuallyField,
  ] = useLocalStorage(USER_SETTINGS.SHOW_INCREASE_DEPOSIT_ANNUALLY_FIELD, false)
  const [
    showIncreaseWithdrawalAnnuallyField,
    setShowIncreaseWithdrawalAnnuallyField,
  ] = useLocalStorage(
    USER_SETTINGS.SHOW_INCREASE_WITHDRAWAL_ANNUALLY_FIELD,
    true
  )
  const [showTaxRateField, setShowTaxRateField] = useLocalStorage(
    USER_SETTINGS.SHOW_TAX_RATE_FIELD,
    false
  )
  const [showInvestmentGoalField, setShowInvestmentGoalField] = useLocalStorage(
    USER_SETTINGS.SHOW_INVESTMENT_GOAL_FIELD,
    false
  )
  const [showMonthlyBreakdown, setShowMonthlyBreakdown] = useLocalStorage(
    USER_SETTINGS.SHOW_MONTHLY_BREAKDOWN,
    false
  )
  const [enableDarkTheme, setEnableDarkTheme] = useLocalStorage(
    USER_SETTINGS.ENABLE_DARK_THEME,
    true
  )

  const handleShowDecimalCheckboxChange = (e) => {
    setShowDecimalsInCurrency(e.target.checked)
  }

  const handleShowRelativeValueCheckboxChange = (e) => {
    setShowRelativeValue(e.target.checked)
  }

  const handleShowIncreaseDepositAnnuallyField = (e) => {
    setShowIncreaseDepositAnnuallyField(e.target.checked)
  }

  const handleShowIncreaseWithdrawalAnnuallyField = (e) => {
    setShowIncreaseWithdrawalAnnuallyField(e.target.checked)
  }

  const handleShowTaxRateFieldCheckboxChange = (e) => {
    setShowTaxRateField(e.target.checked)
  }

  const handleShowInvestmentGoalFieldCheckboxChange = (e) => {
    setShowInvestmentGoalField(e.target.checked)
  }

  const handleShowMonthlyBreakdownCheckboxChange = (e) => {
    setShowMonthlyBreakdown(e.target.checked)
  }

  const handleEnableDarkThemeCheckboxChange = (e) => {
    setEnableDarkTheme(e.target.checked)
  }

  return (
    <div className="card settings">
      <div className="title">Settings</div>

      <div className="form">
        <div className="input-wrapper">
          <div className="switch-wrapper">
            <label htmlFor="showDecimalsInCurrency">
              Show decimals in currency
            </label>
            <label className="switch" htmlFor="showDecimalsInCurrency">
              <input
                className="no-style"
                type="checkbox"
                name="showDecimalsInCurrency"
                id="showDecimalsInCurrency"
                checked={showDecimalsInCurrency}
                onChange={handleShowDecimalCheckboxChange}
              />
              <span className="slider" />
            </label>
          </div>
        </div>

        <div className="input-wrapper">
          <div className="switch-wrapper">
            <label htmlFor="showRelativeValue">Show relative value</label>
            <label className="switch" htmlFor="showRelativeValue">
              <input
                className="no-style"
                type="checkbox"
                name="showRelativeValue"
                id="showRelativeValue"
                checked={showRelativeValue}
                onChange={handleShowRelativeValueCheckboxChange}
              />
              <span className="slider" />
            </label>
          </div>
        </div>

        <div className="input-wrapper">
          <div className="switch-wrapper">
            <label htmlFor="showIncreaseDepositAnnuallyField">
              Show increase deposit annually field
            </label>
            <label
              className="switch"
              htmlFor="showIncreaseDepositAnnuallyField"
            >
              <input
                className="no-style"
                type="checkbox"
                name="showIncreaseDepositAnnuallyField"
                id="showIncreaseDepositAnnuallyField"
                checked={showIncreaseDepositAnnuallyField}
                onChange={handleShowIncreaseDepositAnnuallyField}
              />
              <span className="slider" />
            </label>
          </div>
        </div>

        <div className="input-wrapper">
          <div className="switch-wrapper">
            <label htmlFor="showIncreaseWithdrawalAnnuallyField">
              Show increase withdrawal annually field
            </label>
            <label
              className="switch"
              htmlFor="showIncreaseWithdrawalAnnuallyField"
            >
              <input
                className="no-style"
                type="checkbox"
                name="showIncreaseWithdrawalAnnuallyField"
                id="showIncreaseWithdrawalAnnuallyField"
                checked={showIncreaseWithdrawalAnnuallyField}
                onChange={handleShowIncreaseWithdrawalAnnuallyField}
              />
              <span className="slider" />
            </label>
          </div>
        </div>

        <div className="input-wrapper">
          <div className="switch-wrapper">
            <label htmlFor="showTaxRateField">Show tax rate field</label>
            <label className="switch" htmlFor="showTaxRateField">
              <input
                className="no-style"
                type="checkbox"
                name="showTaxRateField"
                id="showTaxRateField"
                checked={showTaxRateField}
                onChange={handleShowTaxRateFieldCheckboxChange}
              />
              <span className="slider" />
            </label>
          </div>
        </div>

        <div className="input-wrapper">
          <div className="switch-wrapper">
            <label htmlFor="showInvestmentGoalField">
              Show investment goal field
            </label>
            <label className="switch" htmlFor="showInvestmentGoalField">
              <input
                className="no-style"
                type="checkbox"
                name="showInvestmentGoalField"
                id="showInvestmentGoalField"
                checked={showInvestmentGoalField}
                onChange={handleShowInvestmentGoalFieldCheckboxChange}
              />
              <span className="slider" />
            </label>
          </div>
        </div>

        <div className="input-wrapper">
          <div className="switch-wrapper">
            <label htmlFor="showMonthlyBreakdown">Show monthly breakdown</label>
            <label className="switch" htmlFor="showMonthlyBreakdown">
              <input
                className="no-style"
                type="checkbox"
                name="showMonthlyBreakdown"
                id="showMonthlyBreakdown"
                checked={showMonthlyBreakdown}
                onChange={handleShowMonthlyBreakdownCheckboxChange}
              />
              <span className="slider" />
            </label>
          </div>
        </div>

        <div className="input-wrapper">
          <div className="switch-wrapper">
            <label htmlFor="enableDarkTheme">Dark theme</label>
            <label className="switch" htmlFor="enableDarkTheme">
              <input
                className="no-style"
                type="checkbox"
                name="enableDarkTheme"
                id="enableDarkTheme"
                checked={enableDarkTheme}
                onChange={handleEnableDarkThemeCheckboxChange}
              />
              <span className="slider" />
            </label>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Settings
