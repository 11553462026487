import { useFormik } from 'formik'
import { cloneDeep } from 'lodash'
import { Fragment, memo, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import * as Yup from 'yup'

import Input from '../components/Input'
import InvestmentForm from '../components/InvestmentForm'
import Result from '../components/Result'

import useLocalStorage from '../hooks/useLocalStorage'
import { INVESTMENT_FORM_DEFAULT_DATA, USER_SETTINGS } from '../utils/constants'
import { calculateCompoundInterest, validateNumber } from '../utils/helper'

const InvestmentCalculator = () => {
  const [results, setResults] = useState([])
  const [showInvestmentGoalField] = useLocalStorage(
    USER_SETTINGS.SHOW_INVESTMENT_GOAL_FIELD,
    false
  )

  const initialValues = {
    investmentGoal: '',
    principal: '',
    investmentForms: [{ id: uuidv4(), ...INVESTMENT_FORM_DEFAULT_DATA }],
  }

  const investmentFormSchema = Yup.object().shape(
    {
      interestRate: Yup.number().required().min(0),
      interestPeriod: Yup.string(),
      tenureYears: Yup.number().when('tenureMonths', {
        is: (tenureMonths) => !tenureMonths || tenureMonths < 0,
        then: (schema) => schema.required().min(1),
      }),
      tenureMonths: Yup.number().when('tenureYears', {
        is: (tenureYears) => !tenureYears || tenureYears < 0,
        then: (schema) => schema.required().min(1),
      }),
      compoundingFrequency: Yup.string(),
      hasDepositsAndWithdrawals: Yup.string(),
      deposit: Yup.string().test('isValid', 'invalid', (value) =>
        validateNumber(value)
      ),
      depositFrequency: Yup.string(),
      increaseDeposit: Yup.number(),
      withdrawal: Yup.string().test('isValid', 'invalid', (value) =>
        validateNumber(value)
      ),
      withdrawalFrequency: Yup.string(),
      increaseWithdrawal: Yup.number(),
      taxRate: Yup.number(),
    },
    [['tenureYears', 'tenureMonths']]
  )

  const validationSchema = Yup.object({
    investmentGoal: Yup.string(),
    principal: Yup.string().test('isValid', 'invalid', (value) =>
      validateNumber(value, { min: 0, required: true })
    ),
    investmentForms: Yup.array().of(investmentFormSchema),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
  })

  const addInvestmentHandler = (e, id) => {
    e.preventDefault()

    const forms = cloneDeep(formik.values.investmentForms)
    let index = forms.findIndex((form) => form.id === id)
    index = index === -1 ? 1 : index + 1

    formik.setFieldValue('investmentForms', [
      ...forms.slice(0, index),
      { id: uuidv4(), ...INVESTMENT_FORM_DEFAULT_DATA },
      ...forms.slice(index),
    ])
  }

  const removeInvestmentHandler = (e, id) => {
    e.preventDefault()

    formik.setFieldValue(
      'investmentForms',
      formik.values.investmentForms.filter(
        (investmentForm) => investmentForm.id !== id
      )
    )
  }

  const calculateReturnsHandler = (e) => {
    e.preventDefault()

    const calculatedResults = []
    let principal = formik.values.principal
    formik.values.investmentForms.forEach((form) => {
      const result = calculateCompoundInterest({ principal, ...form })
      principal = result.finalValue
      calculatedResults.push(result)
    })
    setResults(calculatedResults)
  }

  return (
    <div className="investment-calci">
      <section>
        <form>
          <div className="card form">
            {showInvestmentGoalField && (
              <div className="input-group">
                <label htmlFor="investmentGoal">Investment goal</label>
                <div className="input-wrapper">
                  <Input
                    type="text"
                    name="investmentGoal"
                    id="investmentGoal"
                    value={formik.values.investmentGoal}
                    onBlur={formik.handleBlur}
                    formik={formik}
                  />
                </div>
              </div>
            )}

            <div className="input-group">
              <label htmlFor="principal" className="required">
                Initial investment
              </label>
              <div className="input-wrapper" data-prefix="₹">
                <Input
                  type="text"
                  name="principal"
                  id="principal"
                  inputMode="numeric"
                  value={formik.values.principal}
                  onBlur={formik.handleBlur}
                  formik={formik}
                  commaSeparated
                />
              </div>
            </div>

            <InvestmentForm formik={formik} index={0} />
          </div>
          <div className="btn-group">
            <div></div>
            <button
              className="btn-sm"
              type="button"
              onClick={addInvestmentHandler}
            >
              Add <i className="bx bx-chevrons-down"></i>
            </button>
          </div>
          {formik.values.investmentForms.length > 1
            ? formik.values.investmentForms.slice(1).map((investment, i) => {
                const index = i + 1

                return (
                  <Fragment key={investment.id}>
                    <div className="card form">
                      <InvestmentForm formik={formik} index={index} />
                    </div>
                    <div className="btn-group">
                      <button
                        className="btn-sm danger"
                        type="button"
                        onClick={(e) =>
                          removeInvestmentHandler(e, investment.id)
                        }
                      >
                        Remove <i className="bx bx-chevrons-up"></i>
                      </button>
                      <button
                        className="btn-sm"
                        type="button"
                        onClick={(e) => addInvestmentHandler(e, investment.id)}
                      >
                        Add <i className="bx bx-chevrons-down"></i>
                      </button>
                    </div>
                  </Fragment>
                )
              })
            : null}
          <div className="btn-group">
            <button
              type="submit"
              onClick={calculateReturnsHandler}
              disabled={!formik.isValid}
            >
              Calculate returns
            </button>
          </div>
        </form>
      </section>

      {/* Summary Table */}
      <section>
        {results.length ? (
          <>
            {formik.values.investmentGoal ? (
              <div className="goal">{formik.values.investmentGoal}</div>
            ) : null}
            {results.map((values) => (
              <Result key={uuidv4()} result={values} />
            ))}
          </>
        ) : null}
      </section>
    </div>
  )
}

export default memo(InvestmentCalculator)
