import { Route, Routes } from 'react-router-dom'

import AppLayout from './layouts/AppLayout'
import InvestmentCalculator from './pages/InvestmentCalculator'
import PageNotFound from './pages/PageNotFound'
import Settings from './pages/Settings'

const App = () => {
  return (
    <div className="app">
      <Routes>
        <Route path="/" element={<AppLayout />}>
          <Route path="settings" element={<Settings />} />
          <Route path="" element={<InvestmentCalculator />} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  )
}

export default App
