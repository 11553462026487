import React from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import { ROUTES } from '../utils/constants'

const AppLayout = () => {
  const { pathname } = useLocation()

  const currentYear = new Date().getFullYear()

  return (
    <div className="app">
      <div className="navbar">
        <div className="logo">
          <Link to={ROUTES.HOME}>Calci</Link>
        </div>
        <div className="menu">
          <Link
            to={ROUTES.SETTINGS}
            className={pathname === ROUTES.SETTINGS ? 'active' : null}
          >
            <i className="bx bxs-user-detail"></i>
          </Link>
        </div>
      </div>
      <div className="content">
        <Outlet />
      </div>
      <div className="footer">
        <a className="pfwiki" href="https://pfwiki.in">
          &copy; pfwiki {currentYear}
        </a>
      </div>
    </div>
  )
}

export default AppLayout
