import React from 'react'

import Input from './Input'

import useLocalStorage from '../hooks/useLocalStorage'
import { USER_SETTINGS } from '../utils/constants'

const InvestmentForm = ({ formik, index }) => {
  const interestRateField = `investmentForms.${index}.interestRate`
  const interestPeriodField = `investmentForms.${index}.interestPeriod`
  const tenureYearsField = `investmentForms.${index}.tenureYears`
  const tenureMonthsField = `investmentForms.${index}.tenureMonths`
  const compoundingFrequencyField = `investmentForms.${index}.compoundingFrequency`
  const noDepositsAndWithdrawalsField = `investmentForms.${index}.noAdditionalDepositOrWithdrawal`
  const onlyDepositField = `investmentForms.${index}.onlyDeposit`
  const onlyWithdrawalField = `investmentForms.${index}.onlyWithdrawal`
  const depositAndWithdrawalField = `investmentForms.${index}.depositAndWithdrawal`
  const depositField = `investmentForms.${index}.deposit`
  const depositFrequencyField = `investmentForms.${index}.depositFrequency`
  const increaseDepositField = `investmentForms.${index}.increaseDeposit`
  const withdrawalField = `investmentForms.${index}.withdrawal`
  const withdrawalFrequencyField = `investmentForms.${index}.withdrawalFrequency`
  const increaseWithdrawalField = `investmentForms.${index}.increaseWithdrawal`
  const taxRate = `investmentForms.${index}.taxRate`

  const hasDepositsAndWithdrawals =
    formik.values.investmentForms[index].hasDepositsAndWithdrawals

  const [showIncreaseDepositAnnuallyField] = useLocalStorage(
    USER_SETTINGS.SHOW_INCREASE_DEPOSIT_ANNUALLY_FIELD,
    false
  )
  const [showIncreaseWithdrawalAnnuallyField] = useLocalStorage(
    USER_SETTINGS.SHOW_INCREASE_WITHDRAWAL_ANNUALLY_FIELD,
    true
  )
  const [showTaxRateField] = useLocalStorage(
    USER_SETTINGS.SHOW_TAX_RATE_FIELD,
    false
  )

  return (
    <>
      <div className="input-group">
        <label htmlFor={interestRateField} className="required">
          Expected rate of returns
        </label>
        <div className="input-sub-group">
          <div className="input-wrapper" data-suffix="%">
            <Input
              type="text"
              name={interestRateField}
              id={interestRateField}
              inputMode="numeric"
              value={formik.values.investmentForms[index].interestRate}
              onBlur={formik.handleBlur}
              formik={formik}
            />
          </div>
          <select
            name={interestPeriodField}
            id={interestPeriodField}
            value={formik.values.investmentForms[index].interestPeriod}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            <option value="monthly">Monthly</option>
            <option value="quarterly">Quarterly</option>
            <option value="half-yearly">Half-yearly</option>
            <option value="yearly">Yearly</option>
          </select>
        </div>
      </div>
      <div className="input-group">
        <label htmlFor={tenureYearsField} className="required">
          Holding period
        </label>
        <div className="input-sub-group">
          <div className="input-wrapper" data-suffix="years">
            <Input
              type="text"
              name={tenureYearsField}
              id={tenureYearsField}
              inputMode="numeric"
              value={formik.values.investmentForms[index].tenureYears}
              onBlur={formik.handleBlur}
              formik={formik}
            />
          </div>
          <div className="input-wrapper" data-suffix="months">
            <Input
              type="text"
              name={tenureMonthsField}
              id={tenureMonthsField}
              inputMode="numeric"
              value={formik.values.investmentForms[index].tenureMonths}
              onBlur={formik.handleBlur}
              formik={formik}
            />
          </div>
        </div>
      </div>
      <div className="input-group">
        <label htmlFor={compoundingFrequencyField}>Compounding frequency</label>
        <select
          name={compoundingFrequencyField}
          id={compoundingFrequencyField}
          value={formik.values.investmentForms[index].compoundingFrequency}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        >
          <option value="monthly">Monthly</option>
          <option value="quarterly">Quarterly</option>
          <option value="half-yearly">Half-yearly</option>
          <option value="yearly">Yearly</option>
          <option value="never">Never</option>
        </select>
      </div>
      <div className="input-group">
        <label>Additional deposits and withdrawals</label>
        <div className="radio-group">
          <div className="radio-sub-group">
            <input
              type="radio"
              name={`investmentForms.${index}.hasDepositsAndWithdrawals`}
              id={noDepositsAndWithdrawalsField}
              value="none"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              checked={
                formik.values.investmentForms[index]
                  .hasDepositsAndWithdrawals === 'none'
              }
            />
            <label htmlFor={noDepositsAndWithdrawalsField}>None</label>
          </div>
          <div className="radio-sub-group">
            <input
              type="radio"
              name={`investmentForms.${index}.hasDepositsAndWithdrawals`}
              id={onlyDepositField}
              value="deposit"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              checked={
                formik.values.investmentForms[index]
                  .hasDepositsAndWithdrawals === 'deposit'
              }
            />
            <label htmlFor={onlyDepositField}>Deposit</label>
          </div>
          <div className="radio-sub-group">
            <input
              type="radio"
              name={`investmentForms.${index}.hasDepositsAndWithdrawals`}
              id={onlyWithdrawalField}
              value="withdrawal"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              checked={
                formik.values.investmentForms[index]
                  .hasDepositsAndWithdrawals === 'withdrawal'
              }
            />
            <label htmlFor={onlyWithdrawalField}>Withdrawal</label>
          </div>
          <div className="radio-sub-group">
            <input
              type="radio"
              name={`investmentForms.${index}.hasDepositsAndWithdrawals`}
              id={depositAndWithdrawalField}
              value="both"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              checked={
                formik.values.investmentForms[index]
                  .hasDepositsAndWithdrawals === 'both'
              }
            />
            <label htmlFor={depositAndWithdrawalField}>Both</label>
          </div>
        </div>
      </div>

      {/* Deposit */}
      {['both', 'deposit'].includes(hasDepositsAndWithdrawals) && (
        <>
          <div className="input-group">
            <label htmlFor={depositField}>Deposit</label>
            <div className="input-sub-group">
              <div className="input-wrapper" data-prefix="₹">
                <Input
                  type="text"
                  name={depositField}
                  id={depositField}
                  inputMode="numeric"
                  value={formik.values.investmentForms[index].deposit}
                  onBlur={formik.handleBlur}
                  formik={formik}
                  commaSeparated
                />
              </div>
              <select
                name={depositFrequencyField}
                id={depositFrequencyField}
                value={formik.values.investmentForms[index].depositFrequency}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="monthly">Monthly</option>
                <option value="quarterly">Quarterly</option>
                <option value="half-yearly">Half-yearly</option>
                <option value="yearly">Yearly</option>
              </select>
            </div>
          </div>
          {showIncreaseDepositAnnuallyField && (
            <div className="input-group">
              <label htmlFor={increaseDepositField}>
                Increase deposit annually by
              </label>
              <div className="input-wrapper" data-suffix="%">
                <Input
                  type="text"
                  name={increaseDepositField}
                  id={increaseDepositField}
                  inputMode="numeric"
                  value={formik.values.investmentForms[index].increaseDeposit}
                  onBlur={formik.handleBlur}
                  formik={formik}
                />
              </div>
            </div>
          )}
        </>
      )}

      {/* Withdrawal */}
      {['both', 'withdrawal'].includes(hasDepositsAndWithdrawals) && (
        <>
          <div className="input-group">
            <label htmlFor={withdrawalField}>Withdrawal</label>
            <div className="input-sub-group">
              <div className="input-wrapper" data-prefix="₹">
                <Input
                  type="text"
                  name={withdrawalField}
                  id={withdrawalField}
                  inputMode="numeric"
                  value={formik.values.investmentForms[index].withdrawal}
                  onBlur={formik.handleBlur}
                  formik={formik}
                  commaSeparated
                />
              </div>
              <select
                name={withdrawalFrequencyField}
                id={withdrawalFrequencyField}
                value={formik.values.investmentForms[index].withdrawalFrequency}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="monthly">Monthly</option>
                <option value="quarterly">Quarterly</option>
                <option value="half-yearly">Half-yearly</option>
                <option value="yearly">Yearly</option>
              </select>
            </div>
          </div>
          {showIncreaseWithdrawalAnnuallyField && (
            <div className="input-group">
              <label htmlFor={increaseWithdrawalField}>
                Increase withdrawal annually by
              </label>
              <div className="input-wrapper" data-suffix="%">
                <Input
                  type="text"
                  name={increaseWithdrawalField}
                  id={increaseWithdrawalField}
                  inputMode="numeric"
                  value={
                    formik.values.investmentForms[index].increaseWithdrawal
                  }
                  onBlur={formik.handleBlur}
                  formik={formik}
                />
              </div>
            </div>
          )}
        </>
      )}

      {showTaxRateField && (
        <div className="input-group">
          <label htmlFor={taxRate}>Tax rate</label>
          <div className="input-wrapper" data-suffix="%">
            <Input
              type="text"
              name={taxRate}
              id={taxRate}
              inputMode="numeric"
              value={formik.values.investmentForms[index].taxRate}
              onBlur={formik.handleBlur}
              formik={formik}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default InvestmentForm
